import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const psychosis: AnswerPathTreeNode = {
  path: 'psychosis',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredPsychosisIsSingleEpisode },
    { path: 'diagnosticDate', nodeId: NodeIds.healthInsuredPsychosisDiagnosticDate },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredPsychosisMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredPsychosisHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultOtherDoctorsOrTherapists,
    },
    { path: 'wentToEmergencyDepartment', nodeId: NodeIds.healthInsuredPsychosisWentToEmergencyDepartment },
    { path: 'wasHospitalized', nodeId: NodeIds.healthInsuredPsychosisWasHospitalized },
    {
      path: 'suicidalThoughtsAndAttempts',
      children: [
        {
          path: 'hadSuicidalThoughtsAndAttempt',
          nodeId: NodeIds.healthInsuredPsychosisHadSuicidalThoughtsAndAttempt,
        },
        {
          path: 'choices',
          nodeId: NodeIds.healthInsuredPsychosisSuicidalThoughtsAndAttemptChoices,
        },
        {
          path: 'suicidalIdeation',
          children: [
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthInsuredPsychosisSuicidalThoughtsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthInsuredPsychosisSuicidalThoughtsFrequency,
            },
          ],
        },
        {
          path: 'attemptedSuicide',
          children: [
            {
              path: 'attemptsNumber',
              nodeId: NodeIds.healthInsuredPsychosisSuicideAttemptsNumber,
            },
          ],
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredPsychosisHadAbsence },
        { path: 'hasRecovered', nodeId: NodeIds.healthInsuredPsychosisAbsenceHasRecovered },
        { path: 'recoveryDate', nodeId: NodeIds.healthInsuredPsychosisAbsenceRecoveryDate },
        { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredPsychosisAbsenceNotRecoveredDetails },
      ],
    },
    {
      path: 'suicideAttemptsDetails',
      nodeId: NodeIds.healthInsuredPsychosisSuicideAttemptsDetails,
      isCollection: true,
      children: [{ path: 'date', nodeId: NodeIds.healthInsuredPsychosisSuicideAttemptsDate }],
    },
    {
      path: 'suicidalThoughtsDetails',
      nodeId: NodeIds.healthInsuredPsychosisSuicidalThoughtsDetails,
      isCollection: true,
      children: [{ path: 'date', nodeId: NodeIds.healthInsuredPsychosisSuicidalThoughtsDate }],
    },
    {
      path: 'hadAbsenceDetails',
      nodeId: NodeIds.healthInsuredPsychosisHadAbsenceDetails,
      isCollection: true,
      children: [
        { path: 'startDate', nodeId: NodeIds.healthInsuredPsychosisAbsenceStartDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredPsychosisAbsenceFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredPsychosisAbsenceFrequency },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredPsychosisMedicationDetails,
      isCollection: true,
      children: [
        { path: 'name', nodeId: NodeIds.healthInsuredPsychosisMedicationName },
        { path: 'dosage', nodeId: NodeIds.healthInsuredPsychosisMedicationDosage },
        { path: 'startDate', nodeId: NodeIds.healthInsuredPsychosisMedicationStartDate },
        { path: 'endDate', nodeId: NodeIds.healthInsuredPsychosisMedicationEndDate },
        { path: 'stillUses', nodeId: NodeIds.healthInsuredPsychosisMedicationStillUses },
        { path: 'hasChanged', nodeId: NodeIds.healthInsuredPsychosisMedicationHasChanged },
        { path: 'hasChangedDetails', nodeId: NodeIds.healthInsuredPsychosisMedicationHasChangedDetails },
      ],
    },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        { path: 'profession', nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsProfession },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsProfessionOther,
        },
        { path: 'name', nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsName },
        { path: 'address', nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsAddress },
        { path: 'firstDate', nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsFirstDate },
        { path: 'lastDate', nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsLastDate },
        { path: 'result', nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsResult },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsFrequencyNumber,
        },
        { path: 'frequency', nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsFrequency },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredPsychosisHasOrWillConsultDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'wentToEmergencyDepartmentDetails',
      nodeId: NodeIds.healthInsuredPsychosisWentToEmergencyDepartmentDetails,
      isCollection: true,
      children: [
        { path: 'date', nodeId: NodeIds.healthInsuredPsychosisWentToEmergencyDepartmentDate },
        { path: 'location', nodeId: NodeIds.healthInsuredPsychosisWentToEmergencyDepartmentLocation },
      ],
    },
    {
      path: 'wasHospitalizedDetails',
      nodeId: NodeIds.healthInsuredPsychosisWasHospitalizedDetails,
      isCollection: true,
      children: [
        { path: 'date', nodeId: NodeIds.healthInsuredPsychosisWasHospitalizedDate },
        { path: 'frequencyNumber', nodeId: NodeIds.healthInsuredPsychosisWasHospitalizedFrequencyNumber },
        { path: 'frequency', nodeId: NodeIds.healthInsuredPsychosisWasHospitalizedFrequency },
        { path: 'location', nodeId: NodeIds.healthInsuredPsychosisWasHospitalizedLocation },
      ],
    },
  ],
};
