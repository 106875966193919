import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const panicAttacks: AnswerPathTreeNode = {
  path: 'panicAttacks',
  children: [
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredPanicAttacksIsSingleEpisode },
    { path: 'diagnosticDate', nodeId: NodeIds.healthInsuredPanicAttacksDiagnosticDate },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredPanicAttacksMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredPanicAttacksHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultOtherDoctorsOrTherapists,
    },
    { path: 'wentToEmergencyDepartment', nodeId: NodeIds.healthInsuredPanicAttacksWentToEmergencyDepartment },
    { path: 'wasHospitalized', nodeId: NodeIds.healthInsuredPanicAttacksWasHospitalized },
    {
      path: 'suicidalThoughtsAndAttempt',
      children: [
        {
          path: 'hadSuicidalThoughtsAndAttempt',
          nodeId: NodeIds.healthInsuredPanicAttacksHadSuicidalThoughtsAndAttempt,
        },
        {
          path: 'choices',
          nodeId: NodeIds.healthInsuredPanicAttacksSuicidalThoughtsAndAttemptChoices,
        },
        {
          path: 'suicidalIdeation',
          children: [
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthInsuredPanicAttacksSuicidalThoughtsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthInsuredPanicAttacksSuicidalThoughtsFrequency,
            },
          ],
        },
        {
          path: 'attemptedSuicide',
          children: [
            {
              path: 'attemptsNumber',
              nodeId: NodeIds.healthInsuredPanicAttacksSuicideAttemptsNumber,
            },
          ],
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredPanicAttacksHadAbsence },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredPanicAttacksAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredPanicAttacksAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredPanicAttacksAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredPanicAttacksMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredPanicAttacksMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredPanicAttacksMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredPanicAttacksMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredPanicAttacksMedicationEndDate,
        },
        {
          path: 'stillUses',
          nodeId: NodeIds.healthInsuredPanicAttacksMedicationStillUses,
        },
        {
          path: 'hasChanged',
          nodeId: NodeIds.healthInsuredPanicAttacksMedicationHasChanged,
        },
        {
          path: 'hasChangedDetails',
          nodeId: NodeIds.healthInsuredPanicAttacksMedicationHasChangedDetails,
        },
      ],
    },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsProfession,
        },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsProfessionOther,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredPanicAttacksHasOrWillConsultDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'wentToEmergencyDepartmentDetails',
      nodeId: NodeIds.healthInsuredPanicAttacksWentToEmergencyDepartmentDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredPanicAttacksWentToEmergencyDepartmentDate,
        },
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredPanicAttacksWentToEmergencyDepartmentLocation,
        },
      ],
    },
    {
      path: 'wasHospitalizedDetails',
      nodeId: NodeIds.healthInsuredPanicAttacksWasHospitalizedDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredPanicAttacksWasHospitalizedDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredPanicAttacksWasHospitalizedFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredPanicAttacksWasHospitalizedFrequency,
        },
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredPanicAttacksWasHospitalizedLocation,
        },
      ],
    },
    {
      path: 'suicidalThoughtsDetails',
      nodeId: NodeIds.healthInsuredPanicAttacksSuicidalThoughtsDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredPanicAttacksSuicidalThoughtsDate,
        },
      ],
    },
    {
      path: 'suicideAttemptsDetails',
      nodeId: NodeIds.healthInsuredPanicAttacksSuicideAttemptsDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredPanicAttacksSuicideAttemptsDate,
        },
      ],
    },
    {
      path: 'hadAbsenceDetails',
      nodeId: NodeIds.healthInsuredPanicAttacksHadAbsenceDetails,
      isCollection: true,
      children: [
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredPanicAttacksAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredPanicAttacksAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredPanicAttacksAbsenceFrequency,
        },
      ],
    },
  ],
};
