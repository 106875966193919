import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const depression: AnswerPathTreeNode = {
  path: 'depression',
  children: [
    {
      path: 'suicidalThoughtsAndAttempt',
      children: [
        {
          path: 'hadSuicidalThoughtsAndAttempt',
          nodeId: NodeIds.healthInsuredDepressionHadSuicidalThoughtsAndAttempt,
        },
        {
          path: 'choices',
          nodeId: NodeIds.healthInsuredDepressionSuicidalThoughtsAndAttemptChoices,
        },
        {
          path: 'suicidalIdeation',
          children: [
            {
              path: 'frequencyNumber',
              nodeId: NodeIds.healthInsuredDepressionSuicidalThoughtsFrequencyNumber,
            },
            {
              path: 'frequency',
              nodeId: NodeIds.healthInsuredDepressionSuicidalThoughtsFrequency,
            },
          ],
        },
        {
          path: 'attemptedSuicide',
          children: [
            {
              path: 'attemptsNumber',
              nodeId: NodeIds.healthInsuredDepressionSuicideAttemptsNumber,
            },
          ],
        },
      ],
    },
    {
      path: 'absence',
      children: [
        { path: 'hadAbsence', nodeId: NodeIds.healthInsuredDepressionHadAbsence },
        {
          path: 'recovery',
          children: [
            { path: 'hasRecovered', nodeId: NodeIds.healthInsuredDepressionAbsenceHasRecovered },
            { path: 'recoveryDate', nodeId: NodeIds.healthInsuredDepressionAbsenceRecoveryDate },
            { path: 'notRecoveredDetails', nodeId: NodeIds.healthInsuredDepressionAbsenceNotRecoveredDetails },
          ],
        },
      ],
    },
    {
      path: 'medicationDetails',
      nodeId: NodeIds.healthInsuredDepressionMedicationDetails,
      isCollection: true,
      children: [
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredDepressionMedicationName,
        },
        {
          path: 'dosage',
          nodeId: NodeIds.healthInsuredDepressionMedicationDosage,
        },
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredDepressionMedicationStartDate,
        },
        {
          path: 'endDate',
          nodeId: NodeIds.healthInsuredDepressionMedicationEndDate,
        },
        {
          path: 'stillUses',
          nodeId: NodeIds.healthInsuredDepressionMedicationStillUses,
        },
        {
          path: 'hasChanged',
          nodeId: NodeIds.healthInsuredDepressionMedicationHasChanged,
        },
        {
          path: 'hasChangedDetails',
          nodeId: NodeIds.healthInsuredDepressionMedicationHasChangedDetail,
        },
      ],
    },
    {
      path: 'hasOrWillConsultDoctorsDetails',
      nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsDetails,
      isCollection: true,
      children: [
        {
          path: 'profession',
          nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsProfession,
        },
        {
          path: 'professionOther',
          nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsProfessionOther,
        },
        {
          path: 'name',
          nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsName,
        },
        {
          path: 'address',
          nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsAddress,
        },
        {
          path: 'firstDate',
          nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsFirstDate,
        },
        {
          path: 'lastDate',
          nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsLastDate,
        },
        {
          path: 'result',
          nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsResult,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsFrequency,
        },
        {
          path: 'furtherConsultation',
          nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsFurtherConsultation,
        },
        {
          path: 'nextConsultationDate',
          nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultDoctorsNextConsultationDate,
        },
      ],
    },
    {
      path: 'wentToEmergencyDepartmentDetails',
      nodeId: NodeIds.healthInsuredDepressionWentToEmergencyDepartmentDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredDepressionWentToEmergencyDepartmentDate,
        },
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredDepressionWentToEmergencyDepartmentLocation,
        },
      ],
    },
    {
      path: 'wasHospitalizedDetails',
      nodeId: NodeIds.healthInsuredDepressionWasHospitalizedDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredDepressionWasHospitalizedDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredDepressionWasHospitalizedFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredDepressionWasHospitalizedFrequency,
        },
        {
          path: 'location',
          nodeId: NodeIds.healthInsuredDepressionWasHospitalizedLocation,
        },
      ],
    },
    {
      path: 'suicidalThoughtsDetails',
      nodeId: NodeIds.healthInsuredDepressionSuicidalThoughtsDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredDepressionSuicidalThoughtsDate,
        },
      ],
    },
    {
      path: 'suicideAttemptsDetails',
      nodeId: NodeIds.healthInsuredDepressionSuicideAttemptsDetails,
      isCollection: true,
      children: [
        {
          path: 'date',
          nodeId: NodeIds.healthInsuredDepressionSuicideAttemptsDate,
        },
      ],
    },
    {
      path: 'hadAbsenceDetails',
      nodeId: NodeIds.healthInsuredDepressionHadAbsenceDetails,
      isCollection: true,
      children: [
        {
          path: 'startDate',
          nodeId: NodeIds.healthInsuredDepressionAbsenceStartDate,
        },
        {
          path: 'frequencyNumber',
          nodeId: NodeIds.healthInsuredDepressionAbsenceFrequencyNumber,
        },
        {
          path: 'frequency',
          nodeId: NodeIds.healthInsuredDepressionAbsenceFrequency,
        },
      ],
    },
    { path: 'isSingleEpisode', nodeId: NodeIds.healthInsuredDepressionIsSingleEpisode },
    { path: 'diagnosticDate', nodeId: NodeIds.healthInsuredDepressionDiagnosticDate },
    { path: 'medicationWasPrescribed', nodeId: NodeIds.healthInsuredDepressionMedicationWasPrescribed },
    { path: 'hasConsultedDoctors', nodeId: NodeIds.healthInsuredDepressionHasConsultedDoctors },
    {
      path: 'hasOrWillConsultOtherDoctorsOrTherapists',
      nodeId: NodeIds.healthInsuredDepressionHasOrWillConsultOtherDoctorsOrTherapists,
    },
    { path: 'wentToEmergencyDepartment', nodeId: NodeIds.healthInsuredDepressionWentToEmergencyDepartment },
    { path: 'wasHospitalized', nodeId: NodeIds.healthInsuredDepressionWasHospitalized },
  ],
};
